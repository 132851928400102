<template>
  <div class="customer-service-container">
    <v-container fluid class="help-cards py-4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="primary--text text--lighten-1">
              {{ $t("faq.needMoreHelp") }}
            </h2>
            <p v-html="$t('faq.needMoreHelpSubtitle')"></p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-card
              class="help-card d-flex flex-column align-center justify-center pa-4 h-100"
              elevation="0"
              outlined
            >
              <v-icon color="secondary" class="help-card-icon" size="50"
                >$form</v-icon
              >
              <v-card-title>
                {{ $t("faq.helpCards.contactForm.title") }}
              </v-card-title>
              <v-card-text
                class="text-center"
                v-html="$t('faq.helpCards.contactForm.descr')"
              ></v-card-text>
              <v-card-actions>
                <v-btn x-large color="secondary" elevation="0" href="/contatti">
                  {{ $t("faq.helpCards.contactForm.cta") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              class="help-card d-flex flex-column align-center justify-center pa-4 h-100"
              elevation="0"
              outlined
            >
              <v-icon color="secondary" class="help-card-icon" size="50"
                >$mail</v-icon
              >
              <v-card-title>
                {{ $t("faq.helpCards.email.title") }}
              </v-card-title>
              <v-card-text
                class="text-center"
                v-html="$t('faq.helpCards.email.descr')"
              ></v-card-text>
              <v-card-actions>
                <v-btn
                  x-large
                  color="secondary"
                  elevation="0"
                  :href="`mailto:${$t('faq.helpCards.email.mail')}`"
                >
                  {{ $t("faq.helpCards.email.cta") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              class="help-card d-flex flex-column align-center justify-center pa-4 h-100"
              elevation="0"
              outlined
            >
              <v-icon color="secondary" class="help-card-icon" size="50"
                >$chat</v-icon
              >
              <v-card-title>
                {{ $t("faq.helpCards.chat.title") }}
              </v-card-title>
              <v-card-text
                class="text-center"
                v-html="$t('faq.helpCards.chat.descr')"
              ></v-card-text>
              <v-card-actions>
                <v-btn
                  x-large
                  color="secondary"
                  elevation="0"
                  @click="callTriggerChat()"
                >
                  {{ $t("faq.helpCards.chat.cta") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              class="help-card d-flex flex-column align-center justify-center pa-4 h-100"
              elevation="0"
              outlined
            >
              <v-icon color="secondary" class="help-card-icon" size="50"
                >$whatsapp</v-icon
              >
              <v-card-title>
                {{ $t("faq.helpCards.whatsapp.title") }}
              </v-card-title>
              <v-card-text
                class="text-center"
                v-html="$t('faq.helpCards.whatsapp.descr')"
              ></v-card-text>
              <v-card-actions>
                <v-btn
                  x-large
                  color="secondary"
                  elevation="0"
                  :href="`https://wa.me/${$t('faq.helpCards.whatsapp.tel')}`"
                >
                  {{ $t("faq.helpCards.whatsapp.cta") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              class="help-card d-flex flex-column align-center justify-center pa-4 h-100"
              elevation="0"
              outlined
              :class="
                customerLoginData &&
                  customerLoginData.visitorTotalOrders > 0 &&
                  'highlight-card'
              "
            >
              <v-icon color="primary lighten-1" class="help-card-icon" size="50"
                >$phone1</v-icon
              >
              <v-card-title>
                {{ $t("faq.helpCards.callUs.title") }}
              </v-card-title>
              <v-card-text
                class="text-center"
                v-html="$t('faq.helpCards.callUs.descr')"
              ></v-card-text>
              <v-card-actions>
                <v-btn
                  x-large
                  color="primary lighten-1"
                  elevation="0"
                  :href="`tel:${$t('faq.helpCards.callUs.tel')}`"
                >
                  {{ $t("faq.helpCards.callUs.cta") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container></v-container
    >
  </div>
</template>
<style lang="scss"></style>
<script>
export default {
  name: "HelpCards",
  methods: {
    callTriggerChat() {
      // Get the iframe element
      var iframe = document.getElementById("chatframe");

      console.log("IFRAME: ", iframe);

      // Send the 'openchat' message to the iframe
      iframe.contentWindow.postMessage("openchat", "*");
    }
  }
};
</script>
